import { A } from 'hookrouter';
import { useEffect } from 'preact/hooks';

import * as styles from './page.module.css';

import { DEFAULT_TITLE, DEFAULT_DESCRIPTION, PAGES } from '../constants';
import logo from '../assets/logo.svg';
import rsIcon from '../assets/Icon-b.svg';

function Page({ mainClass, title, description, children }) {
  const year = new Date().getFullYear();

  useEffect(() => {
    document.title = title || DEFAULT_TITLE;
    document
      .querySelector('meta[name="description"]')
      .setAttribute('content', description || DEFAULT_DESCRIPTION);
  }, [title, description]);

  return (
    <div class={styles.card}>
      <header class={styles.header}>
        <A href={PAGES.HOME}>
          <img alt="Praiseworthy logo" src={logo} />
        </A>
      </header>
      <main class={mainClass}>{children}</main>
      <footer class={styles.footer}>
        <p className="Copyright">
          &copy; {year}{' '}
          <a class={styles.rsIcon} href="https://retrosquare.com">
            <img
              alt="Retrosquare LLC"
              title="Copyright Retrosquare LLC"
              src={rsIcon}
            />
          </a>{' '}
          <A href={PAGES.TERMS}>Terms of Use</A> |{' '}
          <A href={PAGES.PRIVACY}>Privacy Policy</A> |{' '}
          <A href={PAGES.DISCLAIMER}>Disclaimer</A>{' '}
        </p>
      </footer>
    </div>
  );
}

export default Page;
