.main {
  width: 100%;
  max-width: 34rem;
  margin: 2.5rem auto;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.welcome {
  font-size: 1.5rem;
  margin: 0;
  font-weight: 300;
}

.form {
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1.5rem 2.5rem;
}

.label {
  grid-column: 1 / -1;
}

.email {
  grid-column: 1 / 3;
  border: 0.125rem solid #e2e2e2;
  height: 2.5rem;
  width: 100%;
  border-radius: 0.25rem;
  padding: 0 0.5rem;
}

.submit {
  background: #1cafbf;
  border-radius: 0.25rem;
  border: none;
  color: #fff;
  grid-column: 3 / 4;
  height: 2.5rem;
  width: 100%;
}

.appStores {
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1.5rem 2.5rem;
}

.appStores img {
  width: 100%;
  height: 2.5rem;
  display: block;
}

.apple {
  width: 100%;
  grid-column: 2 / 3;
  place-self: flex-start;
}

.google {
  width: 100%;
  grid-column: 1 / 2;
  place-self: flex-start;
}

@media screen and (max-width: 550px) {
  .main,
  .form,
  .appStores {
    display: block;
  }

  .welcome,
  .form,
  .appStores,
  .footer {
    text-align: center;
    margin: 0 auto 2rem auto;
  }

  .google,
  .apple {
    text-align: center;
  }

  .email,
  .google {
    margin-bottom: 0.5rem;
  }

  .appStores img {
    width: auto;
    margin: 0 auto;
  }
}
