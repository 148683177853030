import Page from '../components/Page';
import apple from '../assets/apple-appstore.svg';
import google from '../assets/google-appstore.svg';

import * as styles from './home.module.css';

function Home() {
  return (
    <Page mainClass={styles.main}>
      <>
        <p class={styles.welcome}>
          In a world so consumed by negativity, <br />
          we decided to do something positive.
        </p>
        <form
          noValidate
          action="https://retrosquare.us1.list-manage.com/subscribe/post?u=51c400a4b2543135a79011a0d&amp;id=3953751e33"
          class={styles.form}
          id="mc-embedded-subscribe-form"
          method="post"
          name="mc-embedded-subscribe-form"
          target="_blank"
        >
          <label class={styles.label} htmlFor="mce-EMAIL">
            notify me at launch
          </label>
          <input
            required
            class={styles.email}
            defaultValue=""
            id="mce-EMAIL"
            name="EMAIL"
            placeholder="email address"
            type="email"
          />
          <input
            class={styles.submit}
            id="mc-embedded-subscribe"
            name="subscribe"
            type="submit"
            value="Alert Me"
          />
          <div
            aria-hidden="true"
            style={{ position: 'absolute', left: '-5000px' }}
          >
            <input
              name="b_51c400a4b2543135a79011a0d_3953751e33"
              tabIndex="-1"
              type="text"
              value=""
            />
          </div>
        </form>
        <div class={styles.appStores}>
          <span class={styles.label}>coming soon for</span>
          <div class={styles.google}>
            <img alt="Google Play Store" src={google} />
          </div>
          <div class={styles.apple}>
            <img alt="Apple AppStore" src={apple} />
          </div>
        </div>
      </>
    </Page>
  );
}

export default Home;
