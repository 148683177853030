.card {
  background: rgba(255, 255, 255, 0.35);
  border-radius: 1.6rem;
  border: 0.125rem solid #e2e2e2;
  box-sizing: border-box;
  padding: 2.5rem;
  margin: 4rem;
}

.header {
  width: 100%;
}

.header a {
  border-bottom: 0;
}

.header img {
  max-width: 100%;
}

.footer {
  place-self: center stretch;
}

.footer p {
  margin: 0;
}

.footer a {
  color: #d941a5;
}

.footer a:visited {
  color: #1cafbf;
}

.footer .rsIcon > img {
  height: 1.25rem;
  width: 1.25rem;
  display: inline-block;
  background: white;
  vertical-align: sub;
}

.footer .rsIcon {
  border-bottom: none;
}

@media screen and (max-width: 550px) {
  .card {
    width: auto;
    min-height: 100vh;
    border-radius: 0;
    border: none;
    display: block;
    margin: 0;
  }

  .header,
  .footer {
    text-align: center;
    margin: 0 auto 2rem auto;
  }
}
