export const PAGES = {
  HOME: '/',
  TERMS: '/terms',
  PRIVACY: '/privacy',
  DISCLAIMER: '/disclaimer',
};

export const DEFAULT_TITLE = 'Praiseworthy';
export const DEFAULT_DESCRIPTION =
  'In a world so consumed by negativity, we decided to do something positive.';
